
.md-card {
   background: #fff;
   position: relative;
   box-shadow: 0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.24);
   border: none;
   display: flex;
   flex-direction: column;
   /*height: calc(100vh- 200px);*/
   height: 100%;
}

.md-card .md-card-toolbar {
   flex: none;
   height: 40px;
   padding: 0 20px;
   background: #71277A;
   color: #fff;
}
.md-card .md-card-toolbar.attention {
   background: #008444;
}

.md-card .md-card-toolbar.confirm-delete {
   background: #DC4757;
}

.md-card .md-card-toolbar.highlight {
   background: #71277A;
}

.md-card .md-card-toolbar.darken {
   background: #3B3A39;
}

.md-card .md-card-toolbar .md-card-toolbar-heading-text h3 {
   float: left;
   max-width: 70%;
   height: 38px;
   line-height: 38px;
   text-align: center;
   margin: 0;
   overflow: hidden;
   letter-spacing: 1.2px;
   text-transform: uppercase;
   white-space: nowrap;
   text-overflow: ellipsis;
   color: #fff;
}

.md-card .md-card-toolbar .md-card-toolbar-heading-subtext h4 {
   float: right;
   max-width: 350px;
   height: 38px;
   line-height: 38px;
   text-align: center;
   margin: 0;
   overflow: hidden;
   letter-spacing: 1.2px;
   text-transform: uppercase;
   white-space: nowrap;
   text-overflow: ellipsis;
   color: #fff;
}

/*.ap-card*/
.tab-content {
/* height: calc(100vh - 38px);*/   
   min-height: 150px;
   width: 100%;
   box-sizing: border-box;
}

.user-content {
   /*padding: 24px;
   overflow: auto;*/
   color: #373534;
   min-height: 150px;
   height: 100%;
}

.close-button {
   position: absolute;
   right: 0;
   top: 0;
   border: none;
   display: inline-block;
   padding: 8px 16px;
   vertical-align: middle;
   overflow: hidden;
   text-decoration: none;
   background-color: inherit;
   text-align: center;
   cursor: pointer;
   white-space: nowrap;
   color: #fff;
}