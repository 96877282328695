.fontawesomeIvyGreen {
    filter: invert(30%) sepia(57%) saturate(1939%) hue-rotate(126deg) brightness(96%) contrast(101%);
}

.fontawesomeRed {
    filter: invert(14%) sepia(60%) saturate(6812%) hue-rotate(359deg) brightness(82%) contrast(118%);
}

.fontawesomeGraniteGrey {
    filter: invert(48%) sepia(4%) saturate(275%) hue-rotate(177deg) brightness(94%) contrast(89%);
}

.fontawesomeWhite {
    filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(331deg) brightness(101%) contrast(100%);
}
