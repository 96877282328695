div.homeoverlay {
    background: url(../../images/AP_Admin_Welcome_Screen_Background_2021.png) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: calc(100vh - 50px);
    overflow-x: hidden;
}

.att-container-wrapper {
    padding: 5px;
    background-color: #F5F5F5;
    opacity: 0.7;
    border-radius: 25px;
}

.att-container {
    min-width: 700px;
    /**/
}

.att-container > .att-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.att-container > .att-top > .toprow-left {
    width: 45%;
}

.att-container .img-center {
    content: url(../../images/Triangle_Logo.png);
    height: 80px;
}

.att-container > .att-top > .toprow-right {
    width: 45%;
}

.att-container > .att-top .toprow-top {
    border: 0;
    height: 50%;
}

.att-container > .att-top .toprow-bottom-left {
    height: 50%;
    border-top: 4px solid #71277A;
    border-left: 4px solid #71277A;
    border-start-start-radius: 25px;
}

.att-container > .att-top .toprow-bottom-right {
    height: 50%;
    border-top: 4px solid #71277A;
    border-right: 4px solid #71277A;
    border-start-end-radius: 25px;
}

.att-container .att-bottom {
    width: 100%;
    text-align: center;
}

.att-container .att-body {
    border-right: 4px solid #71277A;
    border-left: 4px solid #71277A;
    border-bottom: 4px solid #71277A;
    padding: 10px;
    border-end-end-radius: 25px;
    border-end-start-radius: 25px;
}

.att-container .title,
.att-container .title h2,
.att-container .title h3 {
    color: #373534;
    font-family: RobotoBold;
    font-weight: bold;
    margin-bottom: 0px;
}

.att-container .title a {
    color: #373534;
    font-size: larger;
    font-weight: bold;
    text-decoration: none;
}

.att-container .title a:hover {
    text-decoration: underline;
}

.att-container .title-paragraph {
    text-align: center;
    color: #000;
    font-style: italic;
    font-size: 16px;
}
