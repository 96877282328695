.loader {
   /*position: fixed;*/
   position: absolute;
   top: 50%;
   -ms-transform: translateY(-50%);
   transform: translateY(-50%);
   right: 0;
   bottom: 0;
   left: 0;
   /* setting a gradient background looks great, but the current display is fixed 400px...need to address that before attempting.  
      background: -webkit-linear-gradient( rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100% );*/
   background: -webkit-linear-gradient( rgba(255, 255, 255, 0) 0% );
   z-index: 9999;
}

.loadercontainer {
    height: 400px;
    position: relative;
}

.loadercontainerEditor {
    position: relative;
}

.content {
    padding: 25px;
    border-radius: 4px;
}