
.profile-title {
   display: flex;
   flex-direction: row;
   padding-bottom: 10px;
}

.profile-title .icon {
     
}

.profile-title .icon img {
   height: 22px;
   width: 22px;
}

   .profile-title .title h3 {
      color: #373534;
      font: RobotoMedium;
      padding-left: 5px;
   }

.profile-field {
   position: relative;
   padding-top: 4px;
   padding-bottom: 10px;
   width: 100%;
   display: block;
   color: #444;
   font-size: 14px;
}

.profile-field > label {
   opacity: .8;
   top: -3px;
   left: 0;
   font-size: 12px;
   color: #727272;
   position: absolute;
   transition: all .15s ease-out;
}

   .profile-field .text {
      vertical-align: middle;
     
      font: 400 15px/18px Roboto,sans-serif;
      box-shadow: inset 0 -1px 0 transparent;
      box-sizing: border-box;
      padding: 12px 4px 9px 10px;
      background: transparent;
      width: 100%;
      display: block;
   }

   .profile-field span.md-input-bar {
      display: block;
      position: absolute;
      top: -5px;
      left: 0;
      width: 100%;
      height: 2px;
   }
   /*.profile-field span.md-input-bar:before {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      width: 0;
      height: 2px;
      transition: width .4s cubic-bezier(.4,0,.2,1);
   }*/
