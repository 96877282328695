@import "../../../Theme/adminportal.css";

.table-scroll table {
   width: 100%;
}

.table-scroll thead, .table-scroll tbody,
.table-scroll tfoot, .table-scroll tr {
   display: table;
   width: 100%;
   table-layout: fixed;
}

.table-scroll tbody {
   display: block;
   overflow-y: scroll;
   table-layout: fixed;
   max-height: calc(100vh - 350px);
}

