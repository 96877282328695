.flyout-menu {
    position: fixed;
    top: 0;
    left: 250px;
    display: inline-block;
    background-color: #fff;
    border: 1px solid #e9e9e9;
    width: 251px;
    height: calc(100vh - 51px);
    overflow: auto;
}

    .flyout-menu.hidden-main {
        top: 0;
        left: 50px;
    }

.flyout-menu-type {
    padding: 15px 10px;
}

h3.heading_a {
    padding: 0;
    margin: 0;
    font: 700 12px Roboto,sans-serif;
    text-transform: uppercase;
}


.flyout-menu .submenu-category {
    white-space: nowrap;
    overflow: hidden;
    /*display: inline-block;*/
}

.flyout-menu .flyout-menu-icon {
    color: #727272;
    vertical-align: middle;
    display: inline-block;
    line-height: unset;
}

    .flyout-menu .flyout-menu-icon img {
        height: 22px;
        width: 22px;
    }

.flyout-menu .flyout-menu-title {
    font-weight: 700;
    text-align: left;
    color: var(--textGrey);
    white-space: nowrap;
    /*overflow: hidden;*/
    text-transform: uppercase;
    padding-left: 8px;
    display: inline-block;
}


.flyout-menu .flyout-menu-items {
    list-style: none;
    font-size: 12px;
    color: var(--textGrey);
}

.flyout-menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

    .flyout-menu ul > li {
        padding: 0px 0px 4px 10px !important;
    }

        .flyout-menu ul > li:hover {
            background-color: transparent !important;
            text-decoration: none;
        }


        .flyout-menu ul > li .flyout-menu-item {
            vertical-align: middle;
            /*   max-width: 200px;*/
            overflow: hidden;
            display: inline-block;
            padding-left: 20px;
            padding-bottom: 12px;
        }

.flyout-menu-label {
    font: 500 12px Roboto,sans-serif;
    color: var(--textGrey);
}
