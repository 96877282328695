
/*
   #F5F5F5 -> selected menu item
   #E9E9E9 -> hover menu
*/

/* menu layout */
.sidebar {
   flex: 0 0 250px;
   width: 250px;
   overflow: hidden;
   border-right: 1px solid rgba(0,0,0,.12);
   background-color: #fff;
   font-size: 12px;
   z-index: 10;
   position: relative; /*position specified to fix z-index issue with ant-table-column-title -> ColumnFilter */
}

.sidebar.collapsed {
   flex: 0 0 50px;
   width: 50px;
   overflow: hidden;
}

.main-sidebar {
   height: calc(100vh - 50px);
}

.ap-sidebar-header {
   display: flex;
   padding: 6px 10px;
}

.ap-sidebar-header .menu-logo {
   max-height: 24px;
   max-width: 150px;
   content: url(../images/AdminPortal_Logo.png);
   padding: 6px 5px 0 15px;
   box-sizing: unset;
}

.ap-sidebar-header .hamburger {
   padding: 8px 0px;
   cursor: pointer;
}

.ap-sidebar-header .hamburger > div {
   width: 20px;
   height: 2px;
   background-color: #f68c3f;
   margin: 4px;
}

.ap-sidebar-header .hamburger.white > div {
   width: 20px;
   height: 2px;
   background-color: #fff;
   margin: 4px;
}

/*.main-menu .menu-items {
}*/

   .menu-items ul {
   list-style: none;
   margin: 0;
   padding: 0;
}

.menu-items ul > li {
   padding: 8px 20px;
}

.menu-items ul > li .menu-item {
   vertical-align: middle;
   line-height: 1;
   height: 22px;
   width: 225px;
   overflow: hidden;
   display: inline-block;
}

.menu-item-inner {
   white-space: nowrap;
   overflow: hidden;
   display: inline-block;
}

.menu-items ul > li:hover {
   background-color: #E9E9E9;
   text-decoration: none;
}

.menu-items ul > li.is-active {
   background-color: #F5F5F5;
   color: #211a17;
   font-family: RobotoMedium; 
}

.menu-items .menu-icon {
   color: #727272;
   vertical-align: middle;
   display: inline-block;
   line-height: unset;
}

.menu-items .menu-icon img {
   height: 22px;
   width: 22px;
}

.menu-items .menu-title {
   text-align: left;
   color: #373534;
   white-space: nowrap;
   /*overflow: hidden;*/
   text-transform: uppercase;
   padding-left: 22px;
   display: inline-block;
}

.ap-sidebar-card {  
   position: relative;
   height: 100%;
   z-index: 1;
}
.ap-sidebar-card .curtain {
   background-color: #fff;
   position: absolute;
   bottom: 50px;
   left: 13px;
   z-index: 1;
   width: 90%;
}