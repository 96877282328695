@import "../../Theme/adminportal.css";

.btn-container {
   padding: 0 5px;
}

.btn-container .md-btn {
   width: 90%;
   text-transform: uppercase;
}

.btn-container button.ap-btn {
   border: 1px solid #ededed;
}

.grid-logo-bg {
   background-color: var(--lavender);
   background-image: linear-gradient(to bottom right, var(--lavender), white);
}

.grid-logo {
   max-height: 100px;
   max-width: 150px;
}

.grid-logo-light-gray {
    background-color: #f2f2f2;
    margin: 1px;
}
.grid-logo-dark-gray {
    background-color: #e2e2e2;
    margin: 1px;
}

.modal-section-bg {
    background-image: radial-gradient(circle, var(--lavender1) 60%, var(--lavender) 125%);
    padding-left: 10px;
    padding-right: 10px;
    border: thin solid;
}