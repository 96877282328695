:root {
    /* Neutral */
    --white: #FFF;
    --lightMediumGrey: #FAFAFA;
    --lightGrey: #FBFBFB;
    --mediumGrey: #EBEBEB;
    --figmaGrey: #F7F7F7F7;
    --neutral3: #BCBEC0;
    --graniteGrey: #747679;
    --textGrey: #373534;
    --darkGrey: #333333;
    --black: #000;
    /* Sky Blue */
    --skyBlue1: #F5FBFD;
    --skyBlue2: #CCEDF8;
    --skyBlue3: #CCEDF8;
    --skyBlue: #419BD8;
    /* sky blue replacement */
    --skyBlue5: #005777;
    /* Steel Blue */
    --steelBlue1: #F5F8FB;
    --steelBlue2: #3379A9;
    --steelBlue: #005893;
    --steelBlue4: #004676;
    --steelBlue5: #003E67;
    /* Purple */
    --lavender1: #D8BFD8;
    --purple1: #FBF8FB;
    --plumPurple: #71277A;
    --purple3: #39143D;
    --lavender: #BF94E4;
    /* Khaki */
    --khaki1: #FCFBF7;
    --khakiTan: #DED3B0;
    --khaki3: #DED3B0;
    --khaki4: #9B947B;
    --khaki5: #6F6A58;
    --khaki6: #433F35;
    /* Red */
    --red1: #FAE6E6;
    --red2: #EB9999;
    --red: #CC0000;
    /* wineRed replacement */
    --red4: #A30000;
    --red5: #8F0000;
    --red6: #520000;
    /* Orange */
    --orange1: #FEFBF8;
    --tangerineOrange: #F68C3F;
    --orange3: #D26C00;
    --orange4: #B05C03;
    --orange5: #9E5303;
    /* Yellow */
    --yellow1: #FFFFF5;
    --yellow2: #F5F751;
    --ocherYellow: #D9C700;
    /* ocherYellow replacement */
    --yellow4: #AC9106;
    --yellow5: #876800;
    /* Green */
    --green1: #F9FCF8;
    --green2: #92E998;
    --ivyGreen: #008540;
    /* ivyGreen replacement */
    --green4: #006A33;
    /*--fontFamily: -apple-system, BlinkMacSystemFont, 'Roboto', 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;*/
    --fontFamily: Roboto,'sans-serif';
}

.auth-layout {
    color: #ffffff;
    min-height: 100vh;
    min-width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url('../images/Plum_Purple_AP_Screen.png');
}

    .auth-layout .logo {
        height: 55px
    }

    .auth-layout h2, p {
        margin: 1em
    }

    .auth-layout p {
        font-size: 14px
    }

    .auth-layout h2, .auth-layout h3 {
        color: #ffffff !important;
    }

    .auth-layout .divider {
        width: 580px;
        height: 3px;
        background-color: #ffffff;
        margin: .5em
    }

    .auth-layout .contact {
        color: var(--tangerineOrange);
    }
    .auth-layout a.contact {
        color: var(--tangerineOrange) !important;
    }
        .auth-layout a.contact:hover {
            text-decoration: underline !important;
        }

    .auth-layout .authContent {
        margin: 3em;
        text-align: center;
    }

        .auth-layout .authContent .button {
            margin-top: 10px;
        }

    .auth-layout .footer {
        position: fixed;
        bottom: 10px;
        right: 10px;
        font-size: smaller;
    }


.skinny-main-menu-scroll {
    margin: 0;
    height: calc(100vh - 190px);
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
}

/* width */
.skinny-scroll ::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.skinny-scroll ::-webkit-scrollbar-track {
    background: var(--mediumGrey);
}

/* Handle */
.skinny-scroll ::-webkit-scrollbar-thumb {
    background: var(--graniteGrey);
}

/* Handle on hover */
.skinny-scroll ::-webkit-scrollbar-thumb:hover {
    background: var(--black);
}

/*font awesome - borrowed from AP */
.fontawesomeSuccessDefault {
    filter: invert(40%) sepia(91%) saturate(421%) hue-rotate(64deg) brightness(89%) contrast(89%);
}

.fontawesomeErrorDefault {
    filter: invert(26%) sepia(90%) saturate(5060%) hue-rotate(357deg) brightness(88%) contrast(77%);
}

.fontawesomeWarningDefault {
    filter: invert(50%) sepia(67%) saturate(456%) hue-rotate(354deg) brightness(99%) contrast(89%);
}
.image-small {
    font-size: 10px;
}
/*smal messages - borrowed from AP */
.smallSuccessMessage,
.smallWarningMessage,
.smallErrorMessage {
    font-family: var(--fontFamily);
    margin-top: 0px;
}

.smallSuccessMessage {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    /* letter-spacing: 1px; */
    margin-bottom: 0;
}

.smallWarningMessage {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    /* letter-spacing: 1px; */
    margin-bottom: 0;
}
.smallErrorMessage {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    /* letter-spacing: 1px; */
    margin-bottom: 0;
}