@font-face {
    font-family: RobotoBlack;
    src: url("https://apcdn.assistpoint.com/fonts/roboto/Roboto-Black.ttf");
}

@font-face {
    font-family: RobotoBlackItalic;
    src: url("https://apcdn.assistpoint.com/fonts/roboto/Roboto-BlackItalic.ttf");
}

@font-face {
    font-family: RobotoBold;
    src: url("https://apcdn.assistpoint.com/fonts/roboto/Roboto-Bold.ttf");
}

@font-face {
    font-family: RobotoBoldItalic;
    src: url("https://apcdn.assistpoint.com/fonts/roboto/Roboto-BoldItalic.ttf");
}

@font-face {
    font-family: RobotoItalic;
    src: url("https://apcdn.assistpoint.com/fonts/roboto/Roboto-Italic.ttf");
}

@font-face {
    font-family: RobotoLight;
    src: url("https://apcdn.assistpoint.com/fonts/roboto/Roboto-Light.ttf");
}

@font-face {
    font-family: RobotoLightItalic;
    src: url("https://apcdn.assistpoint.com/fonts/roboto/Roboto-LightItalic.ttf");
}

@font-face {
    font-family: RobotoMedium;
    src: url("https://apcdn.assistpoint.com/fonts/roboto/Roboto-Medium.ttf");
}

@font-face {
    font-family: RobotoMediumItalic;
    src: url("https://apcdn.assistpoint.com/fonts/roboto/Roboto-MediumItalic.ttf");
}

@font-face {
    font-family: Roboto;
    src: url("https://apcdn.assistpoint.com/fonts/roboto/Roboto-Regular.ttf");
}
/* Preload grid images*/
body::after {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: -1; /*hide images*/
    content: url(./images/AdminPortal_Logo.png) url(./images/AdminPortal_Logo_White.png) url(./images/Triangle_Logo.png) url(./images/Plum_Purple_AP_Screen.png) url(../public/code_runner.png) url(../public/meep404.png) url(../public/403_error_sign.png); /* load images*/
}

body {
    margin: 0;
    padding: 0;
    font-family: --var(--fontFamily);
    font-weight: 400;
    font-size: 12px !important;
    width: 100%;
    min-width: 100%;
    height: 100%;
    min-height: 100%;
}

h1, h2, h3, h4, h5 {
    font-family: --var(--fontFamily) !important;
    /*color: #444;*/
    font-weight: 400;
    margin: 0 0 15px;
    text-transform: none;
}

h3 {
    font-size: 18px;
}

html {
    font-size: 14px;
    /* attempting to preload image used in #dashboard-media.overlay from Home.css */
    background-image: url(./images/AP_Admin_Welcome_Screen_Background_2021.png);
    background-size: 0 0;
}

div.homeoverlay {
    background: url(./images/AP_Admin_Welcome_Screen_Background_2021.png) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: calc(100vh - 50px);
    overflow-x: hidden;
}

div.reportoverlay {
    background: url(./images/AP_Admin_Welcome_Screen_Background.png) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
}


.ap-text-italic {
    font-style: italic;
}

a {
    color: var(--plumPurple) !important;
    text-decoration: none;
}

    a:hover {
        text-decoration: underline !important;
    }

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.full-height {
    height: 100%;
}

.full-viewable-height {
    height: calc(100vh- 200px);
}

.ap-center {
    text-align: center;
}

.ap-text-italic {
    font-family: RobotoItalic,Roboto,sans-serif !important;
}

/* error
.app-Error */
/* layout */
.app-reset {
    display: unset;
    align-items: unset;
}

.padded-container {
    border: 0px;
    max-width: calc(100vw - 265px);
    min-height: 100px;
    height: 100%;
    padding: 25px 10px;
    word-wrap: break-word;
}

.pull-right {
    float: right;
}

.btn-container {
    padding: 0;
    display: flex;
    justify-content: flex-end;
}

    .btn-container .spacer {
        padding-left: 5px;
        padding-right: 5px;
    }

.md-btn.md-btn-primary,
.md-btn.md-btn-primary:active,
.md-btn.md-btn-primary:focus,
.md-btn.md-btn-primary:visited {
    background: #F68C3F;
    color: #fff;
}

    .md-btn.md-btn-primary:hover {
        background: #dd7e38;
        color: #fff;
    }

.md-btn.md-btn-edit,
.md-btn.md-btn-edit:active,
.md-btn.md-btn-edit:focus,
.md-btn.md-btn-edit:visited {
    background: #989898;
    color: #fff;
}

    .md-btn.md-btn-edit:hover {
        background: #808080;
        color: #fff;
    }

.md-btn:active,
.md-btn:focus,
.md-btn:hover,
.md-btn:visited {
    background: #dd7e38;
    outline: none;
    text-decoration: none;
    color: #373534;
    box-shadow: 0 10px 20px rgba(0,0,0,.19), 0 6px 6px rgba(0,0,0,.23);
}

.md-btn {
    background: #F68C3F;
    border: none;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.24);
    min-height: 31px;
    min-width: 70px;
    padding: 2px 16px;
    text-align: center;
    text-shadow: none;
    text-transform: uppercase;
    transition: all .28s cubic-bezier(.4,0,.2,1);
    color: #373534;
    box-sizing: border-box;
    cursor: pointer;
    -webkit-appearance: none;
    display: inline-block;
    vertical-align: middle;
    font: 500 14px/31px Roboto,sans-serif;
}

.btn-container button.ap-btn-disabled-medium {
    cursor: not-allowed;
    pointer-events: none;
    color: #aeaeae;
    background-color: #f2f2f2 !important;
    font-weight: 700;
    /* padding: 10px 0 25px;*/
    text-align: center;
    height: 25px;
}
/* Antd v5 clips top
label {
    opacity: .8;
    top: -10px;
    left: 0;
    font-size: 12px;
}
*/
.md-input-wrapper > label {
    color: #727272;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    transition: all .15s ease-out;
}

input {
    border-radius: 0;
    border-width: 0 0 1px;
    border-style: solid;
    border-color: rgba(0,0,0,.12);
    font: 400 15px/18px Roboto,sans-serif;
    box-shadow: inset 0 -1px 0 transparent;
    box-sizing: border-box;
    padding: 6px 4px;
    padding-left: 0;
    background: transparent;
    width: 100%;
    display: block;
}

    input:read-only {
        color: #373534;
        border-width: 0;
    }

textarea {
    border: 1px dotted rgba(0,0,0,.20);
    border-bottom-style: solid;
    border-radius: 4px;
    outline: 0;
    width: 100%;
    display: block;
    height: 50px;
}

    textarea:focus, input:focus, select:focus {
        border: 1px dotted rgba(0,0,0,.12);
        /*border-bottom-style: solid;
   border-radius: 4px;
   outline: 0;
   box-shadow: none;*/
    }

select {
    border-radius: 0;
    border-width: 0 0 1px;
    border-style: solid;
    border-color: rgba(0,0,0,.12);
    font: 400 15px/18px Roboto,sans-serif;
    box-shadow: inset 0 -1px 0 transparent;
    box-sizing: border-box;
    padding: 5px 4px; /* note the 1 pixel fix to properly align vertical */
    padding-left: 0;
    background: transparent;
    width: 100%;
    display: block;
}

.uk-text-muted {
    color: #757575 !important;
}

input[type='checkbox'],
input[type='radio'] {
    display: unset;
    width: unset;
    margin: 5px;
}

.checkbox-grid {
    display: flex;
    flex-wrap: wrap;
}

    .checkbox-grid > * {
        flex: 1 1 25%;
    }

ul.checkbox-list {
    margin: 0;
    padding: 0;
    list-style: none;
}

ul.checkbox-list li input {
   margin-right: .25em;
}

ul.checkbox-list li {
   border: 1px transparent solid;
   float: left;
   min-width: 50px;
}

ul.checkbox-list li label {
   margin-left: unset;
}

.icon-edit {
    font-size: 16px;
    max-height: 20px;
    vertical-align: middle;
    display: inline-block;
    padding: 0 5px;
    cursor: pointer;
}

.material-icons {
    font-family: Material Icons;
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
    display: inline-block;
    width: 1em;
    height: 1em;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: "liga";
    vertical-align: -4px;
    color: rgba(0,0,0,.54);
}

.icon-view {
    font-size: 16px
}

.dot {
    height: 18px;
    width: 18px;
    background-color: #fff;
    border-radius: 50%;
    border: 2px solid #f68c3f;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    font: 700 12px Roboto,sans-serif;
    color: #f68c3f;
}

.link {
    cursor: pointer;
}

    .link:hover {
        text-decoration: underline;
    }

.grid {
    display: flex;
    /*flex-wrap: wrap;*/
}

.width-1-10 {
    width: 10%;
}

.width-2-10 {
    width: 20%;
}

.width-3-10 {
    width: 30%;
}

.width-4-10 {
    width: 40%;
}

.width-5-10 {
    width: 50%;
}

.width-6-10 {
    width: 60%;
}

.width-8-10 {
    width: 80%;
}

.width-fund-insurance {
    width: 15%;
    border-right: 1px solid #f2f2f2;
}

.width-fund-last-checked {
    width: 20%;
}

.width-fund-last-checked-header {
    width: 12%;
}

.flex-row {
    display: flex;
}

.flex-align-right {
    justify-content: flex-end
}

.flex-align-bottom {
    align-self: flex-end;
}


.container {
    padding: 0;
    display: flex;
}

/* css has officially defeated me :( */
.container-g1 {
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 300px)
}

.container-g1-flex {
    flex: 1 1 auto;
    overflow-y: auto;
    min-height: 0px;
}

/* Center Modal*/
.modal {
    height: auto;
    top: auto;
    left: auto;
    bottom: auto;
    right: auto;
}


.icon-Sort-Default:before {
    content: url(./images/sort_steel_blue_40.png);
    margin-left: 5px;
    color: #a7a6a6;
}

.icon-Sort-Up .path1:before {
    content: url(./images/sortUp_steel_blue.png);
    margin-left: 5px;
}

.icon-Sort-Down .path1:before {
    content: url(./images/sortDown_steel_blue.png);
    margin-left: 5px;
}

.error-text {
   color: var(--red);
   font-size: 12px;
}


.default-image {
    min-width: 200px;
    min-height: 100px;
}

.header-funding-img {
    max-height: 100px;
    height: auto;
    max-width: 300px;
    width: auto;
}


/************************************
   multi-select
************************************/
.ap-multiselect {
    border: 1px solid #d9d9d9;
    background-color: var(--white);
    border-radius: 4px
}

.ap-multiselect:hover {
    border: 1px solid #1677ff; /*** color from antd selected input control ***/
}

.ap-placeholder {
    color: #d9d9d9; /*** matching placeholder on input controls ***/
    font-weight: 500
}
