@import "../Theme/adminportal.css";

.alpaca-overlay-background {
   width: 100%;
   height: 100%;
   z-index: 10000;
   background-image: url('../images/Plum_Purple_AP_Screen.png');
   position: absolute;
   top: 0;
   left: 0;
   display: flex;
   justify-content: center;
   align-items: center;
   overflow: hidden;
}

.alpaca-overlay-background .alpaca-overlay-content {
   max-width: 100%;
   max-height: 100%;
   display: flex;
   flex-flow: row;
   justify-content: center;
   align-items: center;
   position: relative;
}

.alpaca-overlay-background alpaca, 
.alpaca-overlay-background alpaca-overlay-message {
   padding: 10px;
   min-width: 0;
   min-height: 0;
}

.seprate-line {
   width: 3px;
   height: 250px;
   background-color: var(--tangerineOrange);
   margin: 0 6px !important;
}

.alpaca-overlay-background .alpaca-overlay-content > div.alpaca-overlay-message {
   color: #fff;
   font-size: 25px;
}

.alpaca-overlay-background h1 {
   color: var(--tangerineOrange);
   font-family: Roboto;
   font-weight: 700;
   margin: 0 !important;
}

.alpaca-overlay-background .too-small {
   color: #fff;
   font-size: 14px;
   font-weight: 700;
   margin: 0 !important;
}

.alpaca-overlay-background h3 {
   color: #fff;
   font-size: 12px;
   font-weight: 700;
   margin: 0 !important;
}

@media screen and (min-width: 800px) {
   .alpaca-overlay-background {
      display: none;
   }
}