.ap-footer-main {
   position: fixed;
   bottom: 0;
   right: 0;
   height: 50px;
   width: 100%;
   background-color: #71277A;
   color: #fff;
   display: flex;
   align-items: center;
   font-size: 12px;
   z-index: 2;
}

.footer-content {
   position: fixed;
   bottom: 0;
   display: flex;
   height: 50px;
   color: #fff;
   display: flex;
   align-items: center;
   font-size: 12px;
   z-index: 2;
}

.footer-content > .ap-footer-left {
   padding-left: 30px;
}

.ap-footer-main > .ap-footer-right {
   position: absolute;
   right: 0px;
   padding-right: 30px;
}

.ap-footer-shelf {
   height: 50px;
}
