.flex-table {
   /* margin: 15px;
   padding: 0 15px; */
}

   .flex-table .row {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      color: #373534;
      background-color: #fff;
   }

.readonly {
   border: 1px solid #ededed;
   background-color: #e8e8e8;
   border-radius: 5px;
}

.flex-table .row.header {
   border: 1px solid #71277A;
   border-top-left-radius: 4px;
   border-top-right-radius: 4px;
   background-color: #f2f2f2;
}

.flex-table .row.headermain {
   border: 1px solid #71277A;
   border-top-left-radius: 4px;
   border-top-right-radius: 4px;
   background-color: #71277A !important;
   color: #fff !important;
   font-size: small !important;
   text-decoration: underline;
   cursor: default !important;
}

.flex-table .row .heading {
   padding: 5px;
   display: flex;
   flex-direction: row;
   /*flex-basis: 10%;*/
   flex: 1;
   align-items: flex-start;
}

.flex-table .rows {
   border: 1px solid #e2e2e2;
   border-top: none;
   border-bottom-left-radius: 4px;
   border-bottom-right-radius: 4px;
   background-color: #f2f2f2;
   /* height: 100%;*/
   max-height: calc(100vh - 380px);
   overflow-y: scroll;
}

.flex-table .entire-list-rows {
    border: 1px solid #e2e2e2;
    border-top: none;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: #f2f2f2;
    max-height: calc(100vh - 269px);
    overflow-y: scroll;
}

.flex-table .nosearch-entire-list-rows {
    border: 1px solid #e2e2e2;
    border-top: none;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: #f2f2f2;
    max-height: calc(100vh - 213px);
    overflow-y: scroll;
}

.flex-table .footer {
   display: flex;
   justify-content: center;
}

.flex-table .row .column {
   padding: 5px;
   /*display: flex;*/
   flex-direction: column;
   /*flex-basis: 10%;*/
   flex-shrink: 1;
   flex: 1;
   word-break: break-all;
}

   .flex-table .row .text {
      white-space: nowrap;
      overflow: hidden;
      max-width: 15vw;
      text-overflow: ellipsis;
   }

   .flex-table .row .x1 {
      flex-grow: 0; /* do not grow   - initial value: 0 */
      flex-shrink: 0; /* do not shrink - initial value: 1 */
      flex-basis: 5%; /* width/height  - initial value: auto */
   }
   .flex-table .row .x2 {
      flex-grow: 0; 
      flex-shrink: 0; 
      flex-basis: 8%; 
   }
.flex-table .row .x15 {
   flex-grow: 0;
   flex-shrink: 0;
   flex-basis: 15%;
}

.flex-table .row .x50 {
   flex-grow: 0;
   flex-shrink: 0;
   flex-basis: 50%;
}

.flex-table .row.even {
   background-color: #f8f8f8;
}

.flex-table .row.odd {
}

.btn-container .icon-edit {
   vertical-align: middle;
   display: inline-block;
   padding: 0 5px;
}

.btn-container .icon-edit img {
   /*content: url(https://apcdn.assistpoint.com/cdn-images/practice/shared/icons/Application-Edit.svg);*/
   max-height: 20px;
}

.btn-container .icon-edit img:hover {
   box-shadow: 2px 2px 1px #DCDCDC;
}

/*css for view page*/
.view_heading {
    flex: none;
    padding: 24px;
    background: #fff;
    color: #373534;
    position: relative;
    box-sizing: border-box;
}
    .view_heading .content {
        padding: 10px 0;
    }

    .view_heading .content .uk-flex {
        display: -ms-flexbox;
        display: flex;
    }
/*
.uk-flex .default-image {
    min-width: 200px;
    min-height: 100px;
}
.uk-flex .header-funding-img {
    max-height: 100px;
    height: auto;
    max-width: 300px;
    width: auto;
}*/

.uk-flex .header-funding-content {
    max-width: 50%;
    margin-left: 36px;
    text-align: justify;
}

.header-funding-content .data-row {
    width: 100%;
    display: block;
    margin-bottom: 7px;
}
.header-funding-content .label {
    margin-bottom: 3px;
}

.uk-flex .header-funding-bottom-content {
    max-width: 50%;
    margin-left: 15px;
    text-align: justify;
    display: flex;
}

.header-funding-bottom-content .data-row {
    width: 100%;
    display: block;
    margin-bottom: 7px;
    min-width: 110px;
}

.header-funding-bottom-content .label {
    margin-bottom: 3px;
}

.uk-flex label {
    color: #07d;
}
.uk-flex h2 {
    color: #444;
}

.header-row {
    margin: 10px;
    display: flex;
}
.header-row-empty {
    width:70%
}
.search-row-empty {
    width: 30%
}
.addservice-row-empty {
    width: 40%
}
.header-row .content {
    text-decoration: underline;
}

.div-margin{
    padding:10px 0;
}
    .div-margin > label {
        line-height: 2;
    }

.link-service {
    display: block;
    margin:10px;
}

   .link-service .title {
      color: #373534;
      font-weight: bold;
      font-size: initial;
   }

    .link-service .sub-content {
        margin-bottom:5px;
    }

.funding-header {
    background-color: #f2f2f2;
    padding: 7px 0;
    display: flex;
    border: 1px solid #f2f2f2;
    font-weight: bold;
}
.funding-row {
    padding: 7px 0;
    display: flex;
    border: 1px solid #f2f2f2;
}

.funding-row-alt {
    padding: 7px 0;
    display: flex;
    border: 1px solid #f2f2f2;
    background-color: #f2f2f2;
}
.services-row {
    padding: 0;
    display: flex;
    border: 1px solid #f2f2f2;
}
.funding-table {
    height: 325px;
    overflow-y: scroll;
}
.services-row span {
    padding: 9px;
    display: block;
    position: relative;
}

.error-message{
    color: red;
}
.fund-text-align {
    position:absolute;
    top:45%;
}
.fund-insurance-block {
    width:65%
}

.fund-insurance-background {
    background-color: #f2f2f2;
}
.label {
    color: #07d;
}
.content-width {
    width: 100%;
}
.status-image {
    width: 21px;
}

.center {
    text-align: center;
}

.span-title {
    cursor: pointer;
}