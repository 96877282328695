/*
   #F5F5F5 -> selected menu item
   #E9E9E9 -> hover menu
*/
.main-menu {
    /*display: flex;
   flex-direction: row;*/
    max-height: calc(100vh - 175px);
}

.main-menu-left {
    /*display: flex;
   flex-direction: column;*/
}

.main-menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.main-menu ul > li {
    padding: 8px 13px;
}

.main-menu > ul > li > .menu-item:hover {
    cursor: pointer;
}

.main-menu ul > li .menu-item {
    vertical-align: middle;
    line-height: 1;
    height: 22px;
    width: 225px;
    overflow: hidden;
    display: inline-block;
}

.menu-item-inner {
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
}

.main-menu ul > li:hover {
    background-color: #E9E9E9;
    text-decoration: none;
}

.main-menu ul > li.is-active {
    background-color: #F5F5F5;
    color: #211a17;
    font-family: RobotoMedium;
}

.main-menu .menu-title {
    text-align: left;
    color: var(--textGrey);
    white-space: nowrap;
    /*overflow: hidden;*/
    text-transform: uppercase;
    padding-left: 10px;
    display: inline-block;
}
