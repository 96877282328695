/************************************
   Table 
************************************/

/* for the simple pagniation input to stay inline vs the default blocking of inputs in custom css */
li.ant-pagination-simple-pager > input[type=text] {
    width: inherit;
    display: inline;
}

.child-tables .ant-table.ant-table-small .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
    margin: 0px;
    padding: 0px;
}

/************************************
   forms
************************************/

.ant-col-24.ant-form-item-label {
    padding: 0;
}

/************************************
   card
************************************/
.ap-contentcard-container {
    padding-top: 5px;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 0;
    margin-right: 2px;
}

.ap-contentcard.ant-card > .ant-card-body {
    padding: 0;
}

.ap-contentcard.ant-card {
    line-height: 1;
}

    .ap-contentcard.ant-card > .ant-card-head .ant-card-head-title,
    .ap-contentcard.ant-card > .ant-card-head .ant-card-extra {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .ap-contentcard.ant-card > .ant-card-head {
        min-height: unset;
    }


/************************************
   analytics menu - expand
************************************/
.ReportNav .ant-collapse-expand-icon {
    color: var(--plumPurple)
}

.ReportNav .ant-collapse-content-box {
    padding: 0 0 0 0 !important;
}
