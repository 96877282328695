
.modal {
   position: fixed; 
   z-index: 10; 
   left: 0;
   top: 0;
   width: 100%;
   height: 100%;
   overflow: hidden; 
   background-color: rgb(0,0,0);
   background-color: rgba(0,0,0,0.4); 
}

.modal-container {
   position: fixed;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   background-color: #fff;
   border: 1px solid #888;
   border-radius: 5px;
   transition: 1.1s ease-out;
   box-shadow: 2rem 2rem 2rem rgba(0, 0, 0, 0.2);
   width: 80%;
   max-width: 80vw;
   max-height: 80vh;
   overflow: auto;
}

.modal-container.medium {
   max-width: 60vw;
   max-height: 90vh;
}

.modal-container.small {
   max-width: 45vw;
   max-height: 90vh;
}

.modal > .modal-container .modal-toolbar {
   flex: none;
   border-bottom: 1px solid #ccc;
   padding: 5px;
}

.modal h2 {
   margin: 0;
   color: #373534;
}

.modal > .modal-container .modal-toolbar-title {
   text-align: center;
   float: left;
   overflow: hidden;
   text-transform: uppercase;
   max-width: 70%;
   white-space: nowrap;
   text-overflow: ellipsis;
}

.modal > .modal-container .close-modal-button {
   position: absolute;
   right: 0;
   top: 0;
   padding: 6px 8px;
   overflow: hidden;
   text-decoration: none;
   background-color: inherit;
   text-align: center;
   cursor: pointer;
   white-space: nowrap;
   font-weight: bold;
   font-size: 1.5em;
   color: #373534;
}

.modal .content {
   padding: 1rem;
}
