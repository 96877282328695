.ap-footer-help {
    position: fixed;
    bottom: 100px;
    left: 0;
    height: 50px;
    width: 250px;
    z-index: 1;
}

.ap-footer-menu {
   position: fixed;
   bottom: 0;
   left: 0;
   height: 50px;
   width: 250px;
   background-color: #71277A;
   display: flex;
   flex-direction: row;
   border-right: 1px solid #5A1F61; /*rgba(0,0,0,.12);*/
   z-index: 10;
}

   .ap-footer-menu.collapsed {
      width: 50px;
      overflow: hidden;
   }

.ap-footer-menu .item {
   flex: 1;
   padding-top: 14px;
   border-left: 1px solid rgba(0,0,0,.12);
}

.ap-footer-menu .item .tab {
   cursor: pointer;
   color: #373534;
}

.ap-footer-menu .item .tab img, .ap-footer-menu .item .tab span {
   height: 22px;
   width: 22px;
   display: block;
   margin: auto;
   filter: opacity(.9) drop-shadow(0 0 0 #fff);
}
